<template>
  <b-modal :title="`Form ${label}`" v-model="showModal">
    <b-form-group label="Nominal">
      <b-form-input type="number" v-model="form.nominal"></b-form-input>
    </b-form-group>
    <b-form-group label="Keterangan">
      <b-form-textarea v-model="form.keterangan"></b-form-textarea>
    </b-form-group>
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <b-button variant="info" @click.prevent="$emit('submit', this.form)">
          {{ labelType == "reimburse" ? "Claim Reimburse" : "Kirim ke Finance" }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { BModal, BFormGroup, BFormInput, BFormTextarea, BButton } from "bootstrap-vue";
export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
  },
  props: ["active", "label", "labelType"],
  watch: {
    active(val) {
      this.showModal = val;
    },
  },
  data: () => ({
    showModal: null,
    form: {
      nominal: 0,
      keterangan: null,
    },
  }),
};
</script>
